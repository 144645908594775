//
// Custom Variables
//
// Here you can add your own variables overrides
//
// The file is included before any other variable file,
// so you can override them because they all use !default
// --------------------------------------------------

// For example, changing the primary color
// $primary: #a48ad4;

$simple-line-icon-prefix: 'si-';
$fa-css-prefix: 'far';

$space-narrow: 95% !default;
$space-main-max-width: 1440px !default;

$space-base: 1rem;