html.error-page {
  height: 100%;

  body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
  }

  h1 {
    font-size: 7.5em;
    color: #9a26a0;
  }

  .error-description {
    font-size: 2.5em;
  }
}