@import '../oneui/_scss/main.scss';
@import '../oneui/_scss/oneui/themes/amethyst.scss';
@import '../fonts/inter/font.css';

@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~select2/dist/css/select2.css';
@import '~tom-select/dist/css/tom-select.bootstrap5.css';
@import '~tippy.js/dist/tippy.css';
@import './vanillajs-datepicker.css';
@import 'pages/security.scss';
@import 'pages/error.scss';
@import './luckysheet.scss';

@import 'loading';

#page-container.page-header-fixed #main-container {
  padding-top: 3.5rem !important;
}

body.sandbox #page-container.page-header-fixed #main-container {
  padding-top: 5rem !important;
}

.page-content-full {
  max-width: 100% !important;
  width: 100% !important;
}

.form-label.required::after, .mark-required::after {
  content: '*';
  color: $red;
}

a.asc::before {
  content: "\25bc";
  top: calc(50% - 0.75em);
}

a.desc::before {
  content: "\25b2";
  bottom: calc(50% - 0.75em);
}

td, h1 {
  word-wrap: anywhere;
}

.table-sticky {
  th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }
}

.ts-control .item {
  word-break: break-all;
}

.modal-backdrop.fade {
  background-color: rgba(0, 0, 0, 0.5);
}

.metrics {
  .form-check-input.is-invalid ~ .form-check-label {
    color: #000 !important;
  }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #6366F1 !important;
    border-color: #6366F1 !important;
  }
  .was-validated .form-check-input:invalid, .form-check-input.is-invalid {
    border-color: #6366F1 !important;
  }

  .form-check-input.disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
  }
}

.table-mediaplan {
  border: 1px solid #000;
  th, td { padding: 0.25em !important; }
  .dark { background: #ccc0d9; }
  .light { background: #e5dfec; }
}

.btn-excel, .btn-excel:focus {
  color: #fff;
  background-color: #1D6F42;
  border-color: #1D6F42;
}

.btn-excel:hover {
  background-color: darken(#1D6F42, 5);
  color: #fff;
}

.btn-group-copy {
  position: absolute;
  width: 31px;
  right: 10px;
  top: 10px;
}

.table-benchmarks {
  font-size: 0.85em;
  vertical-align: middle;
  text-align: center;

  th {
    vertical-align: middle;
    min-width: 150px;
  }
}

.list-group-small {
  font-size: 0.9em;

  li {
    padding: 5px 10px 5px 10px;
  }
}

.commission-form {
  ul.list-unstyled li {
    display: flex;
    margin-bottom: 10px;
  }
  button.btn-danger {
    margin-left: 5px
  }
}

.ts-wrapper .option .title {
  display: block;
}
.ts-wrapper .option .subtitle {
  font-size: 12px;
  display: block;
  color: #a0a0a0;
}

.ts-dropdown, .ts-dropdown.form-control, .ts-dropdown.form-select {
  z-index: 1001 !important;
}

.ts-dropdown-content {
  background: #fff;
}
.ts-dropdown-content .option.selected {
  background: #ccc0d9;
}
.ts-dropdown-content .option.active {
  background: #e5dfec;
}
.sandbox-warning {
  height: 1.5rem;
  overflow: hidden;
}

.block-options {
  .content-search-icon {
    position: absolute;
    margin: 10px;
    color: #dadddf;
  }
  .content-search-input {
    padding-left: 30px;
  }
}
