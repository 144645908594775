//
// Nav
// --------------------------------------------------

// Nav Pills
.nav-pills {
  .nav-link {
    color: $body-color;

    &:hover,
    &:focus {
      background-color: $body-bg;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}

// Tabs block variation
.nav-tabs-block {
  background-color: $body-bg-light;
  border-bottom: none;

  .nav-item {
    margin-bottom: 0;
  }

  .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-color: transparent;
    border-radius: 0;
    color: $body-color;
    font-size: $font-size-sm;

    &:hover,
    &:focus {
      color: $primary;
      background-color: $body-bg-light;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $body-color;
    background-color: $white;
    border-color: transparent;
  }
}

// Tabs block alternative variation
.nav-tabs-alt {
  background-color: transparent;
  border-bottom-color: $body-bg-dark;

  .nav-item {
    margin-bottom: -1px;
  }

  .nav-link {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: $body-color;
    font-size: $font-size-sm;
    background-color: transparent;
    border: none;

    &:hover,
    &:focus {
      color: $primary;
      background-color: transparent;
      border-color: transparent;
      box-shadow: inset 0 -2px $primary;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $body-color;
    background-color: transparent;
    border-color: transparent;
    box-shadow: inset 0 -2px $primary;
  }
}

// Various Items Navigation
.nav-items {
  @include list-unstyled;

  a {
    &:hover {
      background-color: $body-bg-light;
    }

    &:active {
      background-color: $body-bg;
    }
  }

  > li:not(:last-child) > a {
    border-bottom: 1px solid $body-bg;
  }
}
