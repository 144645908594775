//
// Reboot
// --------------------------------------------------

b,
strong {
  font-weight: $font-weight-bold;
}

// Links
a {
  transition: color 0.12s ease-out;

  @include media-breakpoint-up(sm) {
    &.link-fx {
      position: relative;

      &::before {
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        content: "";
        background-color: $link-color;
        visibility: hidden;
        transform: scaleX(0);
        transition: transform 0.12s ease-out;
      }
    }
  }

  &.link-fx:hover::before,
  &.link-fx:focus::before {
    visibility: visible;
    transform: scaleX(1);
  }
}

// Paragraphs
p {
  line-height: $paragraph-line-height;
}

.story p,
p.story {
  line-height: $paragraph-line-height-lg;
  font-size: 1.125rem;
  color: $gray-700;
}

.story {
  h2,
  h3,
  h4 {
    margin-top: 3rem;
  }
}
