/* Переопределенные стили Luckysheet */

.luckysheet-loading-mask { display: none; }

.luckysheet-filter-options {
  border: none;
  background: #434343;
  margin-top: 5px;
  margin-left: -5px;
  color: #fff;
}

.luckysheet-filter-options:hover {
  background: #f3f3f3;
  color: #000;
}

.luckysheet-freezebar-horizontal-drop-bar {
  height: 4px;
  background: #c7c7c7;
}

.luckysheet-filter-menu {
  #luckysheet-filter-orderby-asc,
  #luckysheet-filter-orderby-desc,
  #luckysheet-filter-orderby-color,
  #luckysheet-filter-bycondition,
  #luckysheet-filter-byvalue,
  #luckysheet-filter-initial {
    display: none;
  }
  .luckysheet-menuseparator { display: none }
  .luckysheet-mousedown-cancel { cursor: pointer }
}

.luckysheet-filter-selected {
  border: 0 !important;
}

.luckysheet-stat-area {
  background: none;
}

.luckysheet-sta-content:hover {
  background: #f0f0f0;
}